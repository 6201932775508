<template>
  <div v-if="centro">
    <v-row v-if="!user">
      <v-col cols="12">
        <h1 class="text-center">{{ $t('Sexo') }}</h1>
      </v-col>
      <v-col class="pt-0 flex-center" cols="12">
        <h2 class="text-center">{{ $t('Elige una opción') }}</h2>
        <v-row class="ma-3 mb-0">
          <v-col cols="12">
            <v-btn depressed
                   min-width="200"
                   @click="reserva.sexo='m'"
                   :color="reserva.sexo === 'm' ? 'primary' : 'normal'">
              {{ $t('Hombre') }}
            </v-btn>
          </v-col>
        </v-row>
        <v-row class="mt-0">
          <v-col cols="12">
            <v-btn depressed
                   min-width="200"
                   @click="reserva.sexo='f'"
                   :color="reserva.sexo === 'f' ? 'primary' : 'normal'">
              {{ $t('Mujer') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-col>
    </v-row>

    <!-- FLOATING ACTION BUTTON -->
    <v-navigation-drawer
        v-if="show_btn_go_to_servicios"
        stateless permanent floating fixed right
        color="transparent"
        :width="'100px'"
        class="pa-5"
    >
      <v-btn
          @click="go_to_servicios"
          fab dark color="primary" class="mb-2">
        <v-icon>mdi-arrow-down</v-icon>
      </v-btn>
    </v-navigation-drawer>


    <div v-if="reserva.sexo!==null">
      <!-- SERVICIOS DESTACADOS -->
      <div v-if="show_servicios_destacados">
        <v-row>
          <v-col>
            <v-alert
                type="primary"
                icon="mdi-alert-decagram"
            >
              {{ $t('Selecciona tu pack') }} 👇
            </v-alert>
          </v-col>
        </v-row>

        <!-- FLOATING ACTION BUTTON -->
        <v-navigation-drawer
            stateless permanent floating fixed right
            color="transparent"
            :width="'100px'"
            class="pa-5"
        >
          <v-btn
              @click="go_to_otras_zonas"
              fab dark color="primary" class="mb-2">
            <v-icon>mdi-arrow-down</v-icon>
          </v-btn>
        </v-navigation-drawer>

        <!-- CHICAS -->
        <template v-if="reserva.sexo==='f'">
          <v-card
              v-for="(current_servicio, indexServicio) in servicios_destacados_chicas" :key="indexServicio"
              class="mt-3"
              elevation="8"
          >

            <v-card-text @click="set_servicio(current_servicio)">
              <v-container fluid>
                <v-layout class="pointer" row @click="">
                  <v-flex xs10>
                    <h3>{{ current_servicio.descripcion }}</h3>
                    <p style="white-space: pre-line;">{{ current_servicio.detalle }}</p>
                  </v-flex>

                  <v-flex xs2 class="text-right">
                    <div v-if="user && user.descuento_online > 0">
                      <div class="precio" style="text-decoration: line-through; color: darkred">
                        {{ current_servicio.precio | money }}
                      </div>
                      <div class="precio" style="font-weight: bold">
                        {{ get_precion_con_descuento(current_servicio) | money }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="precio">{{ current_servicio.precio | money }}</div>
                    </div>

                    <div class="duracion">
                      <v-icon>mdi-timer</v-icon>
                      {{ current_servicio.duracion | time2Minutes }}min
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout class="pointer" row>
                  <v-flex class="text-right font-weight-bold">
                    {{ $t('SELECCIONAR') }}
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>

        <!-- CHICOS -->
        <template v-else-if="reserva.sexo==='m'">
          <v-card
              v-for="(current_servicio, indexServicio) in servicios_destacados_chicos" :key="indexServicio"
              class="mt-3"
              elevation="8"
          >

            <v-card-text @click="set_servicio(current_servicio)">
              <v-container fluid>
                <v-layout class="pointer" row>
                  <v-flex xs10>
                    <h3>{{ current_servicio.descripcion }}</h3>
                    <p style="white-space: pre-line;">{{ current_servicio.detalle }}</p>
                  </v-flex>

                  <v-flex xs2 class="text-right">
                    <div v-if="user && user.descuento_online > 0">
                      <div class="precio" style="text-decoration: line-through; color: darkred">
                        {{ current_servicio.precio_chico | money }}
                      </div>
                      <div class="precio" style="font-weight: bold">
                        {{ get_precion_con_descuento(current_servicio) | money }}
                      </div>
                    </div>
                    <div v-else>
                      <div class="precio">{{ current_servicio.precio_chico | money }}</div>
                    </div>

                    <div class="duracion">
                      <v-icon>mdi-timer</v-icon>
                      {{ current_servicio.duracion | time2Minutes }}min
                    </div>
                  </v-flex>
                </v-layout>
                <v-layout class="pointer" row>
                  <v-flex class="text-right font-weight-bold">
                    {{ $t('SELECCIONAR') }}
                  </v-flex>
                </v-layout>
              </v-container>
            </v-card-text>
          </v-card>
        </template>

        <v-row>
          <v-col>
            <v-btn id="btn_quiero_otras_zonas" class="mt-5" @click="show_servicios_destacados=false" color="primary"
                   block>
              {{ $t('Quiero otras zonas') }}
            </v-btn>
          </v-col>
        </v-row>


      </div>

      <!-- RESTO DE ZONAS/SERVICIOS -->
      <div v-else>
        <v-row id="text_zona" class="mt-3">
          <v-col>
            <h1 class="text-uppercase">{{ $t('Zona') }}</h1>
          </v-col>
        </v-row>

        <v-row v-if="servicios_encontrados.length > 0">
          <v-col>
            <v-alert
                type="primary"
                icon="mdi-alert-decagram"
            >
              {{ $t('Este es el pack que necesitas') }} 👇
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="show_mensaje_selecciona_zonas">
          <v-col>
            <v-alert
                type="primary"
                icon="mdi-alert-decagram"
            >
              {{ $t('Selecciona una o varias zonas') }}
            </v-alert>
          </v-col>
        </v-row>

        <v-row v-if="show_mensaje_no_se_ha_encontrado_pack">
          <v-col>
            <v-alert
                type="info"
                icon="mdi-alert-decagram"
            >
              <p>
                {{
                  $t('No hemos encontrado ningún pack. Puedes seguir seleccionando zonas o solicitar un pack personalizado por Whatsapp')
                }}.
              </p>

              <v-btn
                  small
                  color="primary"
                  :href="`https://api.whatsapp.com/send?phone=39${centro.telefono_whats_app}`"
                  target="_blank"
                  style="margin: 0">
                {{ $t('Abrir WhatsApp con') }} {{ centro.telefono_whats_app }}
              </v-btn>
            </v-alert>
          </v-col>
        </v-row>

        <div id="contServicios" class="contServicios" v-if="centro">
          <div v-for="(current_servicio, indexServicio) in servicios" :key="indexServicio">
            <v-card
                v-if="current_servicio.visible"
                class="mt-3"
                elevation="8"
            >
              <v-card-text @click="set_servicio(current_servicio)">
                <v-container fluid>
                  <v-layout class="pointer" row @click="">
                    <v-flex xs10>
                      <h3>{{ current_servicio.descripcion }}</h3>
                      <p style="white-space: pre-line;">{{ current_servicio.detalle }}</p>
                    </v-flex>

                    <v-flex xs2 class="text-right">
                      <div v-if="user && user.descuento_online > 0">
                        <div class="precio" v-if="reserva.sexo==='f'"
                             style="text-decoration: line-through; color: darkred">{{ current_servicio.precio | money }}
                        </div>
                        <div class="precio" v-if="reserva.sexo==='m'"
                             style="text-decoration: line-through; color: darkred">
                          {{ current_servicio.precio_chico | money }}
                        </div>
                        <div class="precio" style="font-weight: bold">
                          {{ get_precion_con_descuento(current_servicio) | money }}
                        </div>
                      </div>
                      <div v-else>
                        <div class="precio" v-if="reserva.sexo==='f'">{{ current_servicio.precio | money }}</div>
                        <div class="precio" v-if="reserva.sexo==='m'">{{ current_servicio.precio_chico | money }}</div>
                      </div>
                      <div class="duracion">
                        <v-icon>mdi-timer</v-icon>
                        {{ current_servicio.duracion | time2Minutes }}min
                      </div>
                    </v-flex>
                  </v-layout>
                  <v-layout class="pointer" row>
                    <v-flex class="text-right font-weight-bold">
                      {{ $t('SELECCIONAR') }}
                    </v-flex>
                  </v-layout>
                </v-container>
              </v-card-text>
            </v-card>
          </div>
        </div>

        <v-row class="mt-2">
          <v-col>
            <Zonas ref="zonas" @on_servicios_encontrados="on_servicios_encontrados"/>
          </v-col>
        </v-row>
      </div>
    </div>
  </div>
</template>


<script>
import show_toolbar_mixin from "@/mixins/show_toolbar_mixin";
import send_stats_mixin from "../mixins/send_stats_mixin";
import get_utms from '../utils/utm_params'

export default {
  name: 'Centro',
  mixins: [show_toolbar_mixin, send_stats_mixin],

  data() {
    return {
      show_servicios_destacados: true,
      show_mensaje_selecciona_zonas: true,
      show_mensaje_no_se_ha_encontrado_pack: false,
      show_btn_go_to_servicios: false,
      servicios: [], // Servicios del centro
      servicios_encontrados: [], // IDs de los servicios encontrados
      servicios_destacados_chicas: [], // Servicios destacados para chicas
      servicios_destacados_chicos: [], // Servicios destacados para chicos
      include_genitales_masculinos: false,
      utms: get_utms()
    }
  },

  methods: {
    async get_centro() {
      await this.$store.dispatch('centro/get_centro', this.$route.params.slug)
    },

    get_servicios_destacados() {
      this.servicios_destacados_chicas = []
      this.servicios_destacados_chicos = []

      this.centro.servicios.forEach((current_servicio) => {
        this.servicios.push({...current_servicio})
        if (current_servicio.destacado_chica) {
          this.servicios_destacados_chicas.push({...current_servicio})
        } else if (current_servicio.destacado_chico) {
          this.servicios_destacados_chicos.push({...current_servicio})
        }
      })
    },

    init_reserva() {
      this.$store.commit('reserva/reset_reserva')
      this.$store.commit('reserva/set_centro', this.$store.state.centro.centro.id)
    },

    on_servicios_encontrados(servicios_encontrados) {

      const SERVICIO_GENITALES_MASCULINOS_ID = 29756

      // Clonamos los servicios del centro
      this.servicios = []
      this.centro.servicios.forEach((current_servicio) => {
        this.servicios.push({...current_servicio})
      })

      this.servicios_encontrados = []
      this.include_genitales_masculinos = false
      this.show_btn_go_to_servicios = false
      this.show_mensaje_selecciona_zonas = false
      this.show_mensaje_no_se_ha_encontrado_pack = false

      if (servicios_encontrados === null) {
        this.show_mensaje_selecciona_zonas = true
        this.servicios.forEach(function (current_servicio) {
          current_servicio.visible = false
        })

      } else {
        this.servicios_encontrados = servicios_encontrados
        if (this.servicios_encontrados.length === 0) {
          this.show_mensaje_no_se_ha_encontrado_pack = true
        }
        const extraer_servicio_genitales_masculinos = this.$refs.zonas.selected.length > 1 && this.servicios_encontrados.length >= 1 && this.servicios_encontrados.includes(SERVICIO_GENITALES_MASCULINOS_ID)
        const GENITALES_MASCULINOS = this.servicios.filter((s) => s.id === SERVICIO_GENITALES_MASCULINOS_ID)[0]

        if (extraer_servicio_genitales_masculinos) {
          this.include_genitales_masculinos = true
        }

        this.servicios.forEach((current_servicio) => {
          current_servicio.visible = false
          if (this.servicios_encontrados.includes(current_servicio.id)) {
            // Comprobamos si va más de un servicio incluido y uno de ellos son los genitales masculinos, los extraemos
            if (extraer_servicio_genitales_masculinos) {
              if (current_servicio.id !== SERVICIO_GENITALES_MASCULINOS_ID) {
                current_servicio.visible = true
                // Sumamos precio, duración y concatenamos la descripción del servicio
                current_servicio.descripcion += ` + ${GENITALES_MASCULINOS.descripcion}`
                // Si el servicio no tiene detalle, establecemos que el detalle sea el nombre del servicio para que no
                // salga la palabra undefined
                if (!current_servicio.detalle) current_servicio.detalle = current_servicio.descripcion
                current_servicio.detalle += ` + ${GENITALES_MASCULINOS.detalle}`
                current_servicio.precio += GENITALES_MASCULINOS.precio
                current_servicio.precio_chico += GENITALES_MASCULINOS.precio_chico
                const duracion_moment_current_servicio = this.$moment(current_servicio.duracion, 'HH:mm A')
                let vTime = GENITALES_MASCULINOS.duracion.split(':')
                let hours = parseInt(vTime[0])
                let minutes = parseInt(vTime[1])
                current_servicio.duracion = duracion_moment_current_servicio.add({hours, minutes}).format('HH:mm')
              }
            } else {
              current_servicio.visible = true
            }
          }
        })
        if (this.servicios_encontrados.length) {
          this.show_btn_go_to_servicios = true
        }
      }


    },

    go_to_servicios() {
      // document.getElementsByClassName('duracion')[0].scrollIntoView()
      document.getElementById('text_zona').scrollIntoView()
    },

    go_to_otras_zonas() {
      document.getElementById('btn_quiero_otras_zonas').scrollIntoView()
    },

    set_servicio(servicio) {
      this.$store.commit('reserva/set_servicio', servicio)
      this.$store.commit('reserva/set_include_genitales_masculinos', this.include_genitales_masculinos)
      this.$router.push({ name: 'calendar', query: this.utms })
    },

    get_precion_con_descuento(servicio) {
      let precio = this.user.sexo === "m" ? servicio.precio_chico : servicio.precio_chico
      return (precio - (this.user.descuento_online * precio / 100)).toFixed(2)
    }
  },

  async mounted() {
    await this.get_centro()
    this.init_reserva()
    this.get_servicios_destacados()
    this.reserva.sexo = this.user ? this.$store.state.auth.user.sexo : null
  },

  computed: {
    centro() {
      return this.$store.state.centro.centro
    },
    reserva() {
      return this.$store.state.reserva
    },
    user() {
      return this.$store.state.auth.user
    }
  },

  filters: {
    time2Minutes(value) {
      let vTime = value.split(':');
      let hours = parseInt(vTime[0]);
      let minutes = parseInt(vTime[1]);
      return ((hours * 60) + minutes);
    }
  },

  components: {
    Zonas: () => import('@/components/Zonas')
  }
}
</script>


<style lang="scss">
.v-btn--is-elevated {
  margin-top: 60px;
}

.flex-center {
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
